import axios from 'axios';

// Create an Axios instance
const instance = axios.create({
  baseURL: 'https://audio.inocloud.com/api/', // your base URL
  timeout: 10000000 // your desired timeout
});

// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
  const pointer = JSON.parse(localStorage.getItem('msal.account.keys'));
  // console.log(pointer[0]);
  const pointer2 = JSON.parse(localStorage.getItem(pointer));
  // console.log(pointer2);
  const token_jwt = localStorage.getItem('token');

  // const token = pointer2.clientInfo;
  config.headers.Authorization =  token_jwt ? `Bearer ${token_jwt}` :  pointer2.clientInfo;
  return config;
});

// Add a method to get the user photo from the Graph API
instance.getUserPhoto = async function() {
  try {
    const response = await instance.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
      responseType: 'blob'
    });
    return URL.createObjectURL(response.data);
  } catch (error) {
    console.error(`Failed to fetch user photo: ${error}`);
  }
};

export default instance;