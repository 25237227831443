<template>
  <div class="upload-container">
    <div id="waveform"></div>
    <div class="table-container">
    <!-- <button @click="submitFiles()">Process Audio</button> -->
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Duration</th>
          <th>Action</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(file, index) in files" :key="index">
          
          <td>
            <font-awesome-icon class="tomid" @click="deleteFile(file)" :icon="['fas', 'trash']" />
          </td>
          <td>{{ file.name }}</td>
          <td class="text-right">{{ formatDuration(file.duration) }}</td>
          <td>
            <font-awesome-icon class="tomid" @click="playAudio(file)" v-if="file.isplaying!==true" :icon="['fas', 'play']" />
            <font-awesome-icon class="tomid" @click="pauseAudio(false, file)" v-if="file.isplaying===true"  :icon="['fas', 'pause']" /> 
          </td>
          <td>  
            <button @click="process(file)">Process</button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td>Total:</td>
          <td class="text-right">{{ getTotalDuration() }}</td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
    </div>
    <div>
      <label class="upload-label">
        <input type="file" ref="file" multiple accept="audio/*"  @change="handleFilesUpload()" class="upload-input" />
        Choose File
      </label>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import WaveSurfer from 'wavesurfer.js'



export default {
  name: "FileUpload",
  data() {
    return {
      files: [],
      currentAudio: null,
      currentFile: null,
      waveSurfer: null,
    };
  },
  
  mounted() {
    const accounts = this.$store.state.msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      return;
    }
    this.account = accounts[0];
    this.$emitter.emit('login', this.account);
    console.log(this.account);
  },
  methods: {
    createWaveSurfer(file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.waveSurfer = WaveSurfer.create({
          container: '#waveform',
          waveColor: 'violet',
          progressColor: 'purple'
        });

        const audioData = event.target.result;
        this.waveSurfer.loadBlob(new Blob([audioData], { type: file.type }));
      };

      reader.readAsArrayBuffer(file);
    },
    playAudio(file) {
      if(this.currentAudio){
        this.pauseAudio(true, file);
      }


      const audio = new Audio();
      audio.src = URL.createObjectURL(file.file);
      // this.createWaveSurfer(file.file);

      audio.play();
      this.currentAudio = audio;
      this.currentFile = file;
      file.isplaying = true;
    },

    pauseAudio(param, file) {
      console.log(param);
      console.log(file);
  
      if(this.currentFile){
        file.isplaying = false;
      }
      if(this.currentAudio){
        this.currentAudio.pause();
      }

      if(param!==true){
        this.currentAudio = false;
        this.currentFile = file;
      }
    },

    async handleFilesUpload() {
      const files = Array.from(this.$refs.file.files);
      for (const file of files) {
        try {
          const duration = await this.getAudioFileDuration(file);
          this.files.push({ name: file.name, duration, file });

        } catch (error) {
          console.error(error);
        }
      }
    },
    async process(file) {
      try {
        console.log(file.file);
        let formData = new FormData();
        formData.append('file', file.file, file.file.name);
        formData.append('name', this.account.name);
        formData.append('username', this.account.username);

        const response = await api.post('/process', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response.data);

      } catch (error) {
        console.error(`Failed to process file: ${error}`);
      }
    },
    async submitFiles() {
      const formData = new FormData();

      this.files.forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
      });

      try {
        const response = await api.post('/fileupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response.data);
      } catch (error) {
        console.error(`Failed to upload files: ${error}`);
      }
    },
    async getAudioFileDuration(file) {
      return new Promise((resolve, reject) => {
        const audio = new Audio();
        audio.addEventListener('loadedmetadata', () => {
          const duration = audio.duration;
          audio.remove();
          resolve(duration);
        });
        audio.addEventListener('error', (error) => {
          audio.remove();
          reject(error);
        });
        audio.src = URL.createObjectURL(file);
      });
    },
    async deleteFile(file) {
      const index = this.files.indexOf(file);
      if (index > -1) {
        this.files.splice(index, 1);
      }
      if(this.currentFile === file){
        this.currentAudio.pause();
      }
    },
    formatDuration(duration) {
      const minutes = Math.floor(duration / 60);
      const seconds = Math.floor(duration % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
    getTotalDuration() {
      const totalDuration = this.files.reduce((total, file) => total + file.duration, 0);
      return this.formatDuration(totalDuration);
    }
  }
};
</script>

<style scoped>
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

.upload-label {
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: rgb(20, 80, 70);
  border: none;
  color: white;
  cursor: pointer;
}

.upload-input {
  display: none;
}

.upload-label:hover {
  background-color: #99d6ea;
  color: rgb(20, 80, 70);
}

.upload-container button {
  /* margin-bottom: 20px; */
  padding: 10px 20px;
  background-color: rgb(20, 80, 70);
  border: none;
  color: white;
  cursor: pointer;
}

.upload-container button:hover {
  background-color: #99d6ea;
  color: rgb(20, 80, 70);
}

.upload-container table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
}

.upload-container th,
.upload-container td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
  color: rgb(20, 80, 70);;
}

.upload-container th {
  background-color: #6abaad87;
}

.upload-container tr:hover {
  background-color: #f5f5f5;
}

.upload-container tfoot td {
  font-weight: bold;
}

.tomid{
  margin: auto;
  width: 100%;
}

.level {
  font-size: 1rem;
}
.text-right{
  text-align: right!important;;
}
.table-container{
  overflow-x: auto;
  width: 100%;
}
</style>
