<template>
    <div class="navbar-brand">
      <a
        class="navbar-item"
        href="https://www.inocloud.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="https://www.inocloud.com/wp-content/uploads/2023/02/inocloud_logo_.png?w=689&ssl=1" />
      </a>
      <div class="navbar-item nav-home">
        <span class="tour"></span>
      </div>
    </div>
  </template>