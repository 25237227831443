import { createStore } from 'vuex';
import { PublicClientApplication } from '@azure/msal-browser';
import api from '@/api';

const store = createStore({
  state() {
    return {
      msalConfig: {
        auth: {
          clientId: '85656c5b-b628-4b5e-9912-5c70c266d0de',
          authority: 'https://login.microsoftonline.com/common',
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      },
      accessToken: '',
      account: undefined,
      msalInstance: undefined,
      interactionInProgress: false, // add this line
    };
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setAccount(state, account) {
      state.account = account;
    },
    setInteractionInProgress(state, status) { // add this mutation
      state.interactionInProgress = status;
    },
  },
  actions: {
    async initializeMsalInstance({ state }) {
      state.msalInstance = new PublicClientApplication(state.msalConfig);
    },
    async SignIn({ state, commit, dispatch }) { // add dispatch here
      if (state.interactionInProgress) { // check if an interaction is in progress
        return;
      }
      commit('setInteractionInProgress', true); // set interaction in progress

      if (!state.msalInstance) {
        await dispatch('initializeMsalInstance'); // change this.dispatch to dispatch
      }
      await state.msalInstance
        .loginPopup({})
        .then(async () => {
          const myAccounts = state.msalInstance.getAllAccounts();
          commit('setAccount', myAccounts[0]);
          console.log(state.account);

          // Send a POST request to your API endpoint
          const response = await api.post('/register', {
            username: state.account.username,
            name: state.account.name,
            homeAccount: state.account.homeAccount,
          });

          // Log the response from the API
          console.log(response.data);
        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
        })
        .finally(() => {
          commit('setInteractionInProgress', false); // reset interaction in progress
        });
    },
    async SignOut({ state, commit, dispatch }) { // add dispatch here
      if (state.interactionInProgress) { // check if an interaction is in progress
        return;
      }
      commit('setInteractionInProgress', true); // set interaction in progress

      if (!state.msalInstance) {
        await dispatch('initializeMsalInstance'); // change this.dispatch to dispatch
      }
      await state.msalInstance
        .logout({})
        .then(() => {
          commit('setAccount', undefined);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          commit('setInteractionInProgress', false); // reset interaction in progress
        });
    },
  },
});

export default store;
