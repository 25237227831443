<template>
  <div id="app">
    <HeaderBar />
    <MainContent msg="Welcome to Your Vue.js App"/>
    <FileUpload />
  </div>
</template>

<script>
import HeaderBar from '@/components/header-bar';
import MainContent from '@/components/MainContent';
import store from '@/store';


export default {
  name: 'App',
  store,
  components: { HeaderBar, MainContent },
};
</script>



<style lang="scss">
@import '@/styles/index.scss';
</style>

<!-- <style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style> -->
