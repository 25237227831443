<template>
  <div class="navbar-menu">
    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">

          <div class="account-info" v-if="account">
            <!-- <img :src="userPhoto" alt="User Photo" v-if="userPhoto" /> -->
            <div>{{ account.name }}</div>
            <div>{{ account.username }}</div>
          </div>
          <div class="account-login">
            <a v-if="!account" title="Log In" @click="SignIn" target="_blank" rel="noopener noreferrer">
              <i class="fas fa-sign-in-alt fa-2x" aria-hidden="false"></i>
            </a>
            <a v-else @click="SignOut" title="Log Out"  target="_blank" rel="noopener noreferrer">
              <i class="fas fa-sign-out-alt fa-2x" aria-hidden="false"></i>
            </a>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import api from '@/api';
import { mapMutations, mapActions } from 'vuex';

export default {
  name: 'HeaderBar',
  data() {
    return {
      account: undefined,
      signin: 'https://microsoft.com',
    };
  },
  async created() {
    await this.$store.dispatch('initializeMsalInstance');
  },
  mounted() {
    const accounts = this.$store.state.msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      return;
    }
    this.account = accounts[0];
    this.$emitter.emit('login', this.account);

  // Create an async function to fetch the photo
  const fetchUserPhoto = async () => {
    try {
      // this.userPhoto = await api.getUserPhoto();
    } catch (error) {
      console.error(`Failed to fetch user photo: ${error}`);
    }
  };

  // Call the function
  fetchUserPhoto();
  },
  watch: {
    '$store.state.account': {
      immediate: true,
      handler(newVal) {
        this.account = newVal;
      },
    },
  },
  methods: {
    ...mapMutations(['setAccessToken']),
    ...mapActions(['SignIn', 'SignOut']),
  },
};
</script>


<style scoped>
  .account-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .account-login {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin:10px
  }
  .fas {
    color: rgb(255, 255, 255);
  }
</style>