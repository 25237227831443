<template>
  <div class="content-container">
    <div class="section content-title-group">
      <h2 class="title">Welcome to the Audio Transcription service</h2>
      <p class="subtitle">
        This is a simple web application that allows you to upload audio files
        and transcribe them using ML and AI.</p>
    </div>
    <div v-if="account">
      <div class="level">
        <div class="level-item">
          <!-- You are logged in as {{ account.name }} -->
          <FileUpload />
          <!-- <form>
            <input type="file" ref="file" multiple="multiple" />
          </form> -->
        </div>
      </div>
    </div>
    <div class="section content-title-group" v-else>

      <div class="login-container" v-if="!account">
        <p class="subtitle">To use the service, please take a moment to sign in.</p>
        <button id="login-button" @click="SignIn">
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/1024px-Microsoft_logo.svg.png" alt="Microsoft logo" height="50px">
          Continue with Microsoft
        </button>

      </div>

      </div>
  </div>
</template>



<script>
// import customTokenCredential from '../CustomTokenProvider';
// import { BlobServiceClient } from '@azure/storage-blob';

import FileUpload from '@/components/FileUpload';
import { PublicClientApplication } from '@azure/msal-browser';
import api from '@/api';
import { mapMutations, mapActions } from 'vuex';


// const storageAccountName = 'cmatskasbackup';

export default {
  name: 'MainContent',
  data() {
    return {
      account: undefined,
      containers: [],
    };
  },
  watch: {
    '$store.state.account': {
      immediate: true,
      handler(newVal) {
        this.account = newVal;
      },
    },
  },
  async created() {
    this.$emitter.on(
      'login',
      async function (account) {
        this.account = account;
      }.bind(this),
    ),
      this.$emitter.on('logout', () => {
        console.log('logging out');
        this.account = undefined;
      });
  },
  submitFiles() {
    const formData = new FormData();

    for (var i = 0; i < this.$refs.file.files.length; i++ ){
        let file = this.$refs.file.files[i];
        formData.append('files[' + i + ']', file);
    }

    api.post('/fileupload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
      }
    ).then(function(){
    })
    .catch(function(){
    });
  },
  components: {
    FileUpload,
  },  
  methods: {
    ...mapMutations(['setAccessToken']),
    ...mapActions(['SignIn', 'SignOut']),
    async getAzureStorageData() {
      if(this.$store.state.accessToken == ''){
        await this.getAccessToken();
      }
    },
    async getAccessToken(){
      let request = {
        scopes: ['https://storage.azure.com/user_impersonation'],
      };
      const msalInstance = new PublicClientApplication(
        this.$store.state.msalConfig,
      );
      try {
        let tokenResponse = await msalInstance.acquireTokenSilent(request);
        this.$store.commit('setAccessToken', tokenResponse.accessToken);
      } catch (error) {
          console.error( 'Silent token acquisition failed. Using interactive mode' );
          let tokenResponse = await msalInstance.acquireTokenPopup(request);
          console.log(`Access token acquired via interactive auth ${tokenResponse.accessToken}`)
          this.$store.commit('setAccessToken',tokenResponse.accessToken);
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>