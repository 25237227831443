// import Vue from 'vue';
import { createApp } from 'vue';
import App from './App.vue';
import Emitter from 'tiny-emitter';
import store from './store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';


library.add(fas);

const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);

// TODO: consider a module to handle this (aka service or composable)
// TODO: perhaps ... useMSAL
app.config.globalProperties.$msalInstance = {};
// TODO: make the emitter a service
app.config.globalProperties.$emitter = new Emitter();

app.use(store).mount('#app');
